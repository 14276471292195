<template>
  <div class="flex vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:m-0 m-4">
      <div class="flex justify-center mb-8">
         <img v-if="appName ==='FlipPay'" src="@/assets/images/logo/flippay-logo.svg" width="auto" height="48px" alt="Flippay" class="mx-auto"/>
              <img v-else-if="appName==='RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" width="auto" height="48px" alt="Rellopay" class="mx-auto"/>
        
      </div>
      <vx-card class="customer-login-card">
        <div class="customer-login-container pt-0 mb-0">
          <h4
            class="mb-0 w-full text-center font-medium lg:text-xl md:text-lg text-sm" style="letter-spacing:-0.5px;color:#666666;background:#F8F8F8;padding:24px 0;"
          >
            Secure your application <br> with a password
          </h4>
          <div class="onboard-login-content">
            <label for="email" class="w-full mb-0 text-base font-light">Email</label>
            <vs-input
              v-validate="'required|email|min:3'"
              name="Email"
              icon-no-border
              icon-pack="feather"
              v-model="form.email"
              class="w-full"
              :disabled="true"
            />
            <div class="text-danger text-sm" v-show="errors.has('Email')">
              {{ errors.first("Email") }}
            </div>

            <label class="w-full mt-8 text-base font-light mb-0" for="password">Password</label>
            <password
              name="Password"
              ref="password"
              class="border-transparent "
              v-validate="{
                required: true,
              }"
              v-model="form.password"
              :toggle="true"
              :badge="false"
              :showStrengthMeter="false"
            />
            <div class="text-danger text-sm" v-show="errors.has('Password')">
              {{ errors.first("Password") }}
            </div>

            <label for="password" class="w-full text-base font-light mt-8 mb-0">Confirm password</label>
            <password
              v-validate="'required|confirmed:password'"
              name="Confirm Password"
              icon-pack="feather"
              v-model="form.confirmPassword"
              class="w-full pass-check"
              type="password"
              :success="
                form.password === form.confirmPassword &&
                form.password !== '' &&
                form.confirmPassword !== ''
              "
              :toggle="true"
              :showStrengthMeter="false"
              :badge="false"
            />
            <div
              class="text-danger text-sm"
              v-show="errors.has('Confirm Password')"
            >
              {{ errors.first("Confirm Password") }}
            </div>

            <vs-button
              v-round
              size="large"
              @click="createPassword"
              class="mt-10 w-full"
              :disabled="isButtonDisabled"
              >Start application</vs-button
            >
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import Password from "vue-password-strength-meter";
import { mapActions } from "vuex";
import store from "../../../store/store";

export default {
  components: { Password },

  data() {
    return {
      logo: require("@/assets/images/logo/flippay-logo.svg"),
      appName: process.env.VUE_APP_NAME ||"FlipPay",
      serverUrl: process.env.VUE_APP_API_URL,
      form: {
        introducerRequestedId: "",
        uniqueKey: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
    };
  },

  methods: {
    ...mapActions("onboardMerchant", [
      "createMyPassword",
      "getOnboardRequestDetail",
    ]),

    formattedUrl(url) {
      if (!url) return "";
      return url.includes("http") ? url : `https://${url}`;
    },

    async createPassword() {
      this.$vs.loading();
      this.createMyPassword(this.form)
        .then((res) => {
          
          this.$vs.loading.close();
          this.$router.push({
            name: "onboarding-form",
            params: { introducerRequestedId: this.form.introducerRequestedId },
          });
          return null;
        })
        .catch((e) => {
          
          this.$vs.loading.close();
          return e.response.data;
        });
    },
  },

  computed: {
    isButtonDisabled() {
      return (
        this.form.email.length === 0 ||
        this.errors.has("Email") ||
        this.form.password.length === 0 ||
        this.errors.has("Password") ||
        this.form.confirmPassword.length === 0 ||
        this.errors.has("Confirm Password")
      );
    },
  },

  created() {
    this.form.introducerRequestedId = this.$route.params.introducerRequestedId;
    const introducerRequestedId = this.form.introducerRequestedId;

    store
      .dispatch("onboardMerchant/getOnboardRequestDetail", {
        introducerId: introducerRequestedId,
      })
      .then((response) => {
        if (response.status == 245) {
          this.$router.push({
            name: "onboarding-login",
            params: { onboardingRequestId: this.form.introducerRequestedId },
          });
          return null;
        }
        this.form.email = response.data.data.email;
        this.form.uniqueKey = response.data.data.onboardingRequestId;
      })
      .catch((error) => {
        
      });
  },
};
</script>